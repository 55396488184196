.course-participant-form-wrapper {
    margin-bottom: 20px;
}

.course-participant {
    display: flex;
    flex-direction: row;
    gap: 10px;
    position: relative;
}

.participant-name {
    width: 60%;
}

.participant-age {
    width: calc(40% - 10px - 40px - 10px);
}

.add-participants-button {
    border-radius: 20px;
    font-size: 15px;
    padding: 5px 10px;
    background-color: var(--green);
}

.remove-participant-button {
    background-color: var(--pink);
    color: white;
    position: absolute;
    padding: 0;
    width: 40px;
    height: 40px;
    top: 35px;
    right: 0;
}

@media screen and (max-width: 820px) {
    .course-participant {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .participant-age,
    .participant-name {
        width: 100%;
    }

    .remove-participant-button {
        position: relative;
        top: 0;
        bottom: 0;
    }

}