.course-wrapper {
    border: 1px solid var(--background);
    margin-bottom: var(--default-margin);

    --background: var(--dark-blue);
    --button-color: var(--pink);
}

.course-content,
.course-header {
    padding: 5px;
}

.course-row,
.course-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: black;
    line-height: 38px;
}

.course-row:not(:last-child) {
    margin-bottom: 10px;
}

.course-header {
    color: white;
    font-family: UniversLTPro-UltraCn;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: .07em;
    background-color: var(--background);
}

.course-button {
    background-color: var(--button-color);
    color: white;
    text-decoration: none;
    min-width: 120px;
    text-align: center;
    border-radius: 20px;
    padding: 0;
    max-height: 40px;
}

.course-button:disabled {
    background-color: var(--light-gray);
}

.course-description p {
    margin: 0;
    line-height: 32px;
    min-height: 32px;
}

@media screen and (max-width: 820px) {
    .course-row {
        flex-direction: column;
    }

    .course-button {
        margin-top: 10px;
    }
}

.content-aligned-at-end {
    justify-content: flex-end;
}