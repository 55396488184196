@import "../../style_variables.css";

.faq-wrapper {
    color: var(--faq-color);
    margin-bottom: var(--default-margin);
}

.faq-header h4 {
    font-size: 25px;
    padding-left: 10px;
}

.faq-header {
    position: relative;
}

.faq-item.active .faq-header h4 {
    color: var(--link-color);
}

.faq-item {
    margin-bottom: calc(var(--default-margin) / 2);
    box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 25%)
}

.faq-body {
    padding: 0px 10px 5px 10px;
}

.faq-body p:not(:last-child) {
    margin-bottom: 25px;
}

.faq-body .footnote {
    font-size: 0.8rem;
    font-weight: bold;
}

.faq-body .pricesWrapper {
    font-size: 1rem;
}

.faq-body span {
    display: block;
}

.faq-body .price {
    font-weight: bold;
}

.faq-body .priceHint {
    font-size: 0.8rem;

}