.course-categories {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.course-category__anfaenger {
    background-image: url('./images/anfaenger.jpeg');
}

.course-category__invert {
    background-image: url('./images/invert.jpeg');
}

.course-category__sommerferien {
    background-image: url('./images/sommerferien.jpeg');
}