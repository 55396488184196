@import "../../style_variables.css";

.input-wrapper {
    margin-bottom: var(--default-margin);
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.input-error {
    border-color: var(--input-border-error);
    outline: var(--input-border-error);
}

.input-error-text {
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--input-border-error);
}

input[type='checkbox'].input-error {
    outline: var(--input-border-error);
    outline-style: auto;
}

.label {
    font-weight: bold;
    font-size: 16px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

textarea {
    resize: none;
    height: 150px;
    overflow: hidden;
}

.remainingCharactersIndicator {
    font-size: 16px;
    align-self: flex-end;
}