@import "../../style_variables.css";

button {
    border-radius: 5px;
    color: #fff;
    padding: 15px;
    font-size: inherit;
    line-height: inherit;
}

.secondary {
    background-color: var(--light-gray);
}

.primary {
    background-color: var(--dark-blue);
}

.button-wrapper {
    display: flex;
    margin-bottom: var(--default-margin);
}

.has-back-button {
    justify-content: flex-start;
}

.has-submit-button {
    justify-content: flex-end;
}

.has-multiple-buttons {
    justify-content: space-between;
}

@media screen and (max-width: 992px) {

    .has-back-button,
    .has-submit-button {
        justify-content: center;
    }
}