@font-face {
    font-family: UniversLTStd_Bold;
    font-weight: 300;
    src: url(../../../../assets/fonts/UniversLTStd-BoldCn.otf);
}

@font-face {
    font-family: UniversLTStd;
    font-weight: 300;
    src: url(../../../../assets/fonts/UniversLTStd.otf);
}

.voucher-preview {
    display: flex;
    position: relative;
    justify-content: center;
    margin-bottom: 25px;
}

.voucher-preview-image {
    width: 550px;
}

.voucher-preview-content {
    position: absolute;
    top: 330px;
}

.voucher-preview-headline,
.voucher-preview-text {
    background: transparent;
    border: none;
    text-align: center;
    color: black;
    opacity: 0.8;
}

.voucher-preview-headline {
    font-size: 30px;
    font-family: UniversLTStd_Bold;
}

.voucher-preview-text {
    font-size: 20px;
    font-family: UniversLTStd;
    overflow: hidden;
    height: 120px;
}

.voucher-preview-credit {
    font-size: 50px;
    font-family: UniversLTStd_Bold;
    margin-top: 60px;
    margin-left: 110px;
    color: black;
    opacity: 0.8;
}

@media screen and (max-width: 820px) {
    .voucher-preview-image {
        width: 350px;
    }

    .voucher-preview-content {
        top: 210px;
    }

    .voucher-preview-headline {
        font-size: 20px;
    }

    .voucher-preview-text {
        font-size: 15px;
        height: 90px;
        line-height: 1rem;
    }

    .voucher-preview-credit {
        margin-top: 5px;
        margin-left: 95px;
        font-size: 35px;
    }

}

@media screen and (max-width: 430px) {

    .voucher-preview-text {
        height: 70px;
    }

    .voucher-preview-credit {
        margin-top: 25px;
        margin-left: 65px;
    }

}