@import "style_variables.css";

body {
  margin: 0;
  font-family: Roboto;
  font-size: var(--default-font-size);
  line-height: var(--default-font-size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: UniversLTPro-UltraCn;
  font-weight: 400;
  src: url(./assets/fonts/univers-ultracondensed.woff2), url(./assets/fonts/univers-ultracondensed.woff)
}

*,
:after,
:before {
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: var(--link-color);
  cursor: pointer;
}

a:hover {
  color: var(--dark-blue);
}

._loading_overlay_overlay {
  background: rgba(0, 0, 0, 0.2);
}

._loading_overlay_wrapper,
.css-79elbk {
  position: inherit !important;
}

#root {
  position: relative;
}