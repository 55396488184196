.cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.card {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    flex-basis: 33%;
    box-shadow: 2px 4px 5px 0 rgba(0, 0, 0, .15)
}

.card__thumbnail {
    height: 300px;
    background-size: cover;
}

.card__title {
    margin-top: 20px;
    color: var(--dark-blue);
    font-size: 30px;
    text-align: center;
}

.card__description {
    margin-top: 10px;
    color: black;
    text-decoration: none;
    padding: 10px;
    text-align: center;
}


@media screen and (max-width: 820px) {
    .card {
        flex-basis: 100%;
    }
}