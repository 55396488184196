.orderOverviewForm {
    margin-bottom: 25px;
}

.orderTable {
    width: 100%;
    border-bottom: 1px solid black;
}

.noHint {
    margin-bottom: 15px;
}

.orderTableRow {
    display: flex;
}

.orderItem_description {
    min-width: 60%;
    text-align: left;
}

.orderItem_column {
    width: 10%;
    text-align: right;
}

.orderTotal {
    width: 100%;
    text-align: right;
    padding-top: 10px;
}

.orderTotalValue {
    font-weight: bold;
}

.orderTotal_info {
    display: block;
    font-size: 12px;
    font-style: italic;
    text-align: right;
}

.cashInVoucherForm {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cashInVoucherField {
    width: 70%;
}

.cashInVoucherButton {
    border-radius: 20px;
    font-size: 15px;
    padding: 5px 10px;
    background-color: var(--green);
}

.orderItem_hint {
    font-size: 13px;
    font-style: oblique;
    line-height: 13px;
    display: block;
    margin-bottom: 15px;
}

@media screen and (max-width: 820px) {
    .cashInVoucherForm {
        flex-direction: column;
        margin-bottom: 25px;
    }

    .cashInVoucherField {
        width: 100%;
    }

    .orderItem_column {
        min-width: 80px;
    }

    .orderItem_column__large {
        min-width: 110px;
    }

    .orderItem_description {
        min-width: 30%;
        flex: 1;
    }
}