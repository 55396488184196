@import "./style_variables.css";

.app-wrapper {
    margin: auto;
    max-width: 80rem;
    margin-bottom: calc(var(--default-margin) * 2);
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
}

.content-wrapper {
    margin-bottom: var(--default-margin);
}

input,
select,
textarea {
    width: 100%;
    padding: 0.5rem;
    border-style: solid;
    border-color: var(--input-border);
    border-width: 1px;
    text-transform: none;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
}

select {
    -webkit-appearance: none;
    appearance: none;
}

.checkbox-wrapper {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    margin-bottom: var(--default-margin);
}

.checkbox-wrapper input {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    padding: 0;
    margin: 0;
}

.hint {
    font-size: 16px;
    display: inline-block;
    margin-bottom: var(--default-margin);
}

.card__largeGroup {
    background-image: url('./assets/images/gruppen.jpeg');
}

.card__courses {
    background-image: url('./assets/images/anfaenger.jpeg');
}

.card__voucher {
    background-image: url('./assets/images/gutscheine.jpeg');
}

.priceIndicatorAndButtonWrapper {
    display: flex;
    justify-content: flex-end;
    gap: 25px;
    align-items: baseline;
}

@media screen and (max-width: 820px) {
    .priceIndicatorAndButtonWrapper {
        flex-direction: column;
    }
}