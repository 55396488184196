:root {
    --footer-text-color: white;
    --dark-blue: #073345;
    --pink: #df1d84;

    --input-border: rgb(202 202 202);
    --default-margin: 20px;

    --input-border-error: rgb(239 68 68);

    --light-gray: #6c757d;

    --link-color: #009ddf;

    --faq-color: #666;

    --default-font-size: 16px;
    --additional-info-font-size: 12px;

    --footer-blue: #009ddf;

    --green: #65b32e;
}