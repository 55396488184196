@import '../../style_variables.css';

footer {
    background-color: var(--footer-blue);
    display: flex;
    padding: 40px;
    justify-content: space-between;
    font-size: 21px;
    color: var(--footer-text-color);
    font-family: UniversLTPro-UltraCn;
    line-height: 24px;
    --link-color: var(--footer-text-color);
}

footer>div {
    flex: 1 1 0px;
}

.contact-info {
    text-align: left;
}

.contact-icons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px
}

.contact-icons .contact-icon {
    background-size: 50px;
}

.contact-social .contact-icon {
    background-size: 30px;
}

.contact-social {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
}

.social-icons,
.contact-legal {
    position: absolute;
    display: flex;
    gap: 15px;
    justify-content: center;
}

.contact-legal {
    bottom: 0;
}

.contact-icon {
    background-color: var(--dark-blue);
    background-position: 50%;
    background-repeat: no-repeat;
    width: 45px;
    height: 45px;
    border-radius: 100%;
}

.contact-icon__phone {
    background-image: url('./icons/CS_SocialIcons-12-3.png');
}

.contact-icon__mail {
    background-image: url('./icons/CS_SocialIcons-10-3.png');
}

.contact-icon__fb {
    background-image: url('./icons/fb.png');
}

.contact-icon__insta {
    background-image: url('./icons/insta.png');
}

.contact-icon__youtube {
    background-image: url('./icons/yt.png');
}

.contact-icon__vimeo {
    background-image: url('./icons/vimeo.png');
}

@media screen and (max-width: 992px) {
    footer {
        flex-direction: column;
        gap: 20px;
    }

    .contact-info {
        text-align: center;
    }

    .social-icons,
    .contact-legal {
        position: relative;
    }

    .contact-social {
        gap: 10px;
    }

    .contact-icons {
        justify-content: center;
    }
}