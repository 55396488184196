.consent-banner {
    background-color: black;
    font-size: 13px;
    line-height: 20px;
    color: white;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: baseline;
    padding: 5px;
}

.consent-button {
    background: var(--footer-blue);
    padding: 10px;
    width: 100px;
}