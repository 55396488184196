@import url('../../style_variables.css');

h1,
h2,
h3,
h4 {
    font-weight: 400;
    font-family: UniversLTPro-UltraCn, Roboto;
}

h2 {
    color: var(--dark-blue);
    font-size: 60px;
    line-height: 72px;
    letter-spacing: .07em;
    text-align: center;
}

h3 {
    color: var(--pink);
    font-size: 30px;
    line-height: 38px;
    letter-spacing: .07em;
    text-align: center;
}

h4 {
    font-weight: 400;
    font-size: 32.5px;
    line-height: 45.5px;
    margin: 0;
}

.faq-headline {
    color: var(--pink);
    font-size: 60px;
    line-height: 72px;
    letter-spacing: .07em;
    text-align: center;
}